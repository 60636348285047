import topic from '../topic'
export const cpttAPI = {
    getCptts: topic.cpttTopic + '/get_cptts',
    getAllCpttsV1: topic.cpttTopic + '/get_all_cptts_v1',
    getCpttById: topic.cpttTopic + '/get_cptt_by_id',
    addCptt: topic.cpttTopic + '/add_cptt',
    editCptt: topic.cpttTopic + '/edit_cptt',
    deletCpttByIds: topic.cpttTopic + '/delete_cptt_by_ids',
    get_my_cptts_v1:topic.cpttTopic + '/get_my_cptts_v1',
    getEmptyCpttsV1:topic.cpttTopic + '/get_empty_cptts_v1',
    cpttShareAccts: topic.cpttTopic + '/cptt_share_accts',
    
}