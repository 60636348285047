<template>
  <div class="vg_wrapper">
    <el-card v-loading='isLoading'>
      <div>
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMsinsNow()">
          <el-row>
            <el-col :md="6">
              <el-form-item label="物料入库单号：">
                <el-input size="small" v-model.trim="searchForm.msin_no" clearable placeholder="请填写合同编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="单据状态：">
                <selectStatus ref="selectStatus" @selectStatusRow="getStatusVal"></selectStatus>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="14">
              <el-form-item label="交货时间：">
                <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMsinsNow()" class="vg_ml_16">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_pointer" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border>
            <el-table-column type="selection" width="48"  align="center"/>
            <el-table-column label="物料入库单号" prop="msin_no" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column label="交货时间" prop="msin_time" :formatter="formatDate" />
            <el-table-column label="单据状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {msinAPI} from "@api/modules/msin"
import selectStatus from "@/views/component/selectStatus";
import pubPagination from "@/components/common/pubPagination";
import {cpttAPI} from "@api/modules/comptitle";
import {acctAPI} from "@api/modules/acct";
export default {
  name: "MsinList",
  components: {
    selectStatus,
    pubPagination
  },
  data(){
    return{
      searchForm:{
        msin_no:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      btn:{},
      acctFctrGroupOpt:[],
      cpttGroupOpt:[],
      isLoading:true
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/msin_add' || from.path === '/msin_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.isLoading = true 
      this.getMsinsList()
    },
    //获取物料采购列表
    getMsinsList(){
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(msinAPI.getMsins,
          {
            msin_no: this.searchForm.msin_no,
            status: this.searchForm.status,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              console.log('res',res)
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
            setTimeout(()=>{
              this.isLoading = false 
            },500)
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        msin_no:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
      };
      this.$refs.selectStatus.changeValue()
      this.currentPage = 1
      this.initData()
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.msin_time)
    },
    // 查询方法
    getMsinsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.isLoading = true 
      this.getMsinsList()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 分页查询
    changePageSearch(val){
      this.$refs.pubPagination.currentPage = 1
      this.isLoading = true 
      this.currentPage = val
      this.getMsinsList()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    // 新增
    addRow(){
      const permId = this.$route.query.perm_id
      this.jump(`/msin_add?perm_id=${permId}`)
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.msin_id)
      })
      post(msinAPI.deleteMsinByIds,{'msin_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
            this.initData()
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/msin_edit',{perm_id:permId,form_id:row.msin_id})
    }
  }
}
</script>

<style scoped>

</style>